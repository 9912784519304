<template>
<span>
    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">Gerenciar Seções de Conteúdo</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="blue-grey darken-2" dark class="mb-1" @click.prevent="atualizarDados">
                <v-icon dark>sync</v-icon>Atualizar
            </v-btn>
            <v-btn color="success" dark class="mb-1 ml-2" :to="{name: 'contentSections.add'}">
                <v-icon class="mdi mdi-plus"></v-icon>Adicionar Nova Seção
            </v-btn>
        </template>
    </v-toolbar>

    <v-card>
        <v-card-title>
            <v-text-field v-model="search" append-icon="search" label="Localizar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="loadContentSections" :search="search" :rowsPerPageItems="rowsPerPageItems">

            <template v-slot:[`item.cont_sec_imagem`]="{ item }">
                <td width="15%" v-if="item.cont_sec_imagem"><img :src="`${base_url}upload/content_section/${item.cont_sec_imagem}?${uuid}`" class="avatar"></td>
                <td width="15%" v-if="!item.cont_sec_imagem"><img :src="`${base_url}upload/logo.png`" class="avatar"></td>
            </template>

            <template v-slot:[`item.lang_flag`]="{ item }">
                <td><img v-if="item.language.lang_flag" :src="`${base_url}upload/language/${item.language.lang_flag}`" class="avatar"></td>
            </template>

            <template v-slot:[`item.cont_sec_status`]="{ item }">
                <td>
                    <v-switch color="lime" v-model="item.cont_sec_status" @change="onChangeEventHandlertStatus(item.cont_sec_id, $event)" class="my-switch"></v-switch>
                </td>
            </template>

            <template v-slot:[`item.cont_sec_publicado`]="{ item }">
                <td>
                    <v-switch color="lime" v-model="item.cont_sec_publicado" @change="onChangeEventHandlertPublicado(item.cont_sec_id, $event)" class="my-switch"></v-switch>
                </td>
            </template>

            <template v-slot:[`item.acoes`]="{ item }">
                <v-tooltip top color="blue white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="blue lighten-1" @click.prevent="editContentSection(item)" v-on="on">
                            <v-icon dark>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar</span>
                </v-tooltip>

                <v-tooltip top color="red white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="red lighten-1" @click.prevent="deleteContentSection(item)" v-on="on">
                            <v-icon dark class="mdi mdi-delete"></v-icon>
                        </v-btn>
                    </template>
                    <span>Excluir</span>
                </v-tooltip>

            </template>

            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">Voce procurou por "{{ search }}" nenhum item localizado.</v-alert>
            </template>

        </v-data-table>
    </v-card>
</span>
</template>

<script>
import {
    URL_BASE
} from "../../../config/configs";

export default {
    created() {
        this.$store.dispatch("loadContentSections");
    },
    computed: {
        loadContentSections() {
            if (this.$store.state.contentsections.items.data == null) return [];

            return this.$store.state.contentsections.items.data;
        }
    },
    name: "ContentSectionComponent",
    data() {
        return {
            search: "",
            rowsPerPageItems: [
                10,
                20,
                30,
                {
                    text: "$vuetify.dataIterator.rowsPerPageAll",
                    "10": -1
                }
            ],
            headers: [{
                    text: "Imagem",
                    align: "left",
                    sortable: false,
                    value: "cont_sec_imagem"
                },
                {
                    text: "Idioma",
                    align: "left",
                    sortable: false,
                    value: "lang_flag"
                },
                {
                    sortable: false,
                    text: "Seção",
                    value: "cont_sec_nome"
                },
                {
                    sortable: false,
                    text: "Tipo",
                    value: "tipo.cont_ty_nome"
                },
                {
                    sortable: false,
                    text: "Descrição",
                    value: "cont_sec_descricao"
                },
                {
                    sortable: false,
                    text: "Status",
                    value: "cont_sec_status"
                },
                {
                    sortable: false,
                    text: "Publicado",
                    value: "cont_sec_publicado"
                },
                {
                    text: "Ações",
                    align: "center",
                    sortable: false,
                    value: "acoes"
                }
            ],
            contentSection: [],
            base_url: URL_BASE
        };
    },
    methods: {
        atualizarDados() {
            this.$store.dispatch("loadContentSections");
        },
        editContentSection(item) {
            this.$router.push({
                name: "contentSections.edit",
                params: {
                    cont_sec_id: item.cont_sec_id
                }
            });
        },
        deleteContentSection(item) {
            const index = this.$store.state.contentsections.items.data.indexOf(item)
            console.log(index)
            this.$swal({
                title: "Você tem certeza?",
                text: "Todos as Categorias e Conteúdos dessa seção serão excluídos",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#D32F2F",
                cancelButtonColor: "#1976D2",
                confirmButtonText: "Sim, Exclua!"
            }).then(result => {
                if (result.value) {
                    this.$store
                        .dispatch("destroyContentSection", item.cont_sec_id)
                        .then(response => {
                            if (response) {
                                this.$swal({
                                    position: "center",
                                    icon: "success",
                                    title: "Sucesso",
                                    text: "Dados excluídos com sucesso",
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                                this.$store.state.contentsections.items.data.splice(index, 1);
                            } else {
                                this.$swal({
                                    position: "center",
                                    icon: "error",
                                    title: "Opssss!",
                                    text: "Erro ao excluir dados",
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                            }
                        })
                        .catch(error => {
                            this.$swal({
                                position: "center",
                                icon: "error",
                                title: "Opssss!",
                                text: "Algo errado aconteceu!",
                                showConfirmButton: true,
                                timer: 6000
                            });
                        });
                }
            });
        },
        onChangeEventHandlertStatus(cont_sec_id, value) {
            this.$store
                .dispatch("updateContentSectionStatus", {
                    cont_sec_id: cont_sec_id,
                    cont_sec_status: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: "Sucesso",
                        text: "Status atualizado com sucesso",
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: "Opssss!",
                        text: "Erro ao atualizar status",
                        showConfirmButton: true,
                        timer: 6000
                    });
                });
        },
        onChangeEventHandlertPublicado(cont_sec_id, value) {
            this.$store
                .dispatch("updateContentSectionPublicado", {
                    cont_sec_id: cont_sec_id,
                    cont_sec_publicado: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: "Sucesso",
                        text: "Status atualizado com sucesso",
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: "Opssss!",
                        text: "Erro ao atualizar status",
                        showConfirmButton: true,
                        timer: 6000
                    });
                });
        }
    }
};
</script>

<style scoped>
.avatar {
    height: 40px;
    margin-top: 0.4rem;
}
</style>
